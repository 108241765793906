<template>
  <v-container>
    <v-row align="center" justify="center" class="text-center pa-4" no-gutters>
      <loading :active.sync="loading"></loading>
      <!-- <v-card width="90%" class="pa-10"> -->
      <v-row align="center" justify="center" class="text-center" no-gutters>
        <v-img
          src="@/assets/logo_survey.png"
          max-width="180px"
          max-height="180px"
        />
      </v-row>
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <p style="font-size: 24px" class="headtitle">
          ระบบแก้ไขข้อมูลการ X-Ray ในชุมชน
        </p>
        <v-card outline width="100%" class="pa-4 mb-4">
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="6">
              <v-text-field
                label="ชื่อผู้บันทึกข้อมูล"
                v-model="createdUser"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <v-text-field
                label="วัน/เวลา ที่อัพเดทข้อมูล"
                v-model="updatedAt"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="รหัสประจำตัวประชาชน/ เลขพาสปอร์ต"
                v-model="identityId"
                counter="13"
                :rules="rules.maxlength13"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="name"
                label="ชื่อ-นามสกุล"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field v-model="nickname" label="ชื่อเล่น"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="gender"
                label="เพศ"
                :items="['ชาย', 'หญิง']"
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="age"
                label="อายุ"
                :rules="rules.required"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="nationality"
                label="สัญชาติ"
                :items="['ไทย', 'ลาว', 'เมียร์มาร์', 'กัมพูชา', 'อื่นๆ']"
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
              xs="12"
              v-if="nationality == 'อื่นๆ'"
              :rules="rules.required"
            >
              <v-text-field
                v-model="otherNationality"
                label="สัญชาติอื่นๆ ระบุ"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field v-model="address" label="ที่อยู่"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="urine"
                :items="urineItems"
                label="การตรวจปัสสาวะบุคคล"
                :rules="rules.required"
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-checkbox
                v-model="acceptFlag"
                label="สมัครใจบำบัด"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row
            v-if="urine == 'พบสารเสพติด' || acceptFlag == true"
            align="center"
            justify="center"
          >
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="phone"
                label="หมายเลขโทรศัพท์"
                counter="10"
                type="number"
                :rules="rules.maxlength10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="caseType"
                :items="['เสพ', 'จำหน่าย', 'ครอบครองเพื่อจำหน่าย', 'ครอบครอง']"
                label="ลักษณะการทำความผิด"
                :rules="rules.required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="drugTimes"
                :items="[
                  'ผู้ใช้ (ผู้เสพรายใหม่เพิ่งเสพครั้งแรก)',
                  'ผู้เสพ (เคยเสพมาก่อน ผ่านการบำบัดไม่เกิน 5 ครั้ง)',
                  'ผู้ติด (เคยเสพมาก่อน ผ่านการบำบัดมากกว่า 5 ครั้ง',
                  'ผู้ติด+ผู้ป่วยจิตเวชจากการใช้สารเสพติด',
                ]"
                label="ลักษณะการเสพ"
                :rules="rules.required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12"> </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <h3>รูปภาพที่ 1</h3>
              <v-img :src="pic1" max-width="20vh" />
              <v-text-field
                v-model="picture_1"
                @click="changePic1()"
                :placeholder="imageName1"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure1"
                accept="image/*"
                @change="showPicture1"
                style="display: none"
            /></v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <h3>รูปภาพที่ 2</h3>
              <v-img :src="pic2" max-width="20vh" />
              <v-text-field
                v-model="picture_2"
                @click="changePic2()"
                :placeholder="imageName2"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure2"
                accept="image/*"
                @change="showPicture2"
                style="display: none"
            /></v-col>

            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="drugType"
                :items="[
                  'ยาบ้า',
                  'เฮโรอีน',
                  'ไอซ์',
                  'กัญชา',
                  'กระท่อม',
                  'ยาเค',
                  'อื่นๆ',
                ]"
                label="สารเสพติดที่พบ"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12" v-if="drugType == 'อื่นๆ'">
              <v-text-field
                v-model="drugTypeOther"
                label="ระบุสารเสพติดที่พบ"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="otherPerson"
                :items="['มี', 'ไม่มี']"
                label="บุคคลผู้เกี่ยวข้อง"
                :rules="rules.required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12" v-if="otherPerson == 'มี'">
              <v-text-field
                v-model="otherPersonDetail"
                label="รายละเอียดผู้เกี่ยวข้อง"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outline
          width="100%"
          class="mb-4"
          v-if="urine == 'พบสารเสพติด' || acceptFlag == true"
          ><v-col cols="12" md="12" sm="12" xs="12">
            <h3 style="font-weight: 800; color: #833133">หลังการบำบัด</h3>
          </v-col>
          <v-col cols="12">
            <v-row
              ><v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="treatment"
                  :items="['เข้ารับการบำบัด', 'ไม่เข้ารับการบำบัด']"
                  label="เข้ารับการบำบัดหรือไม่"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="12" xs="12">
                <v-text-field
                  v-model="updatedAt"
                  label="อัพเดตล่าสุดเมื่อ"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" md="12" sm="12" xs="12" align="left">
            <v-text-field
              v-model="updatedAt"
              label="อัพเดตล่าสุดเมื่อ"
            ></v-text-field>
          </v-col> -->
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine2"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 2"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine2">
                <v-menu
                  ref="menufrom"
                  v-model="menufrom"
                  :close-on-content-click="false"
                  :return-value.sync="urine2CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine2CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 2"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine2CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menufrom = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menufrom.save(urine2CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine3"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 3"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine3">
                <v-menu
                  ref="menuto3"
                  v-model="menuto3"
                  :close-on-content-click="false"
                  :return-value.sync="urine3CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine3CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 3"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine3CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto3 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto3.save(urine3CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >
         
         <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine4"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 4"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine4">
                <v-menu
                  ref="menuto4"
                  v-model="menuto4"
                  :close-on-content-click="false"
                  :return-value.sync="urine4CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine4CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 4"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine4CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto4 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto4.save(urine4CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine5"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 5"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine5">
                <v-menu
                  ref="menuto5"
                  v-model="menuto5"
                  :close-on-content-click="false"
                  :return-value.sync="urine5CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine5CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 5"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine5CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto5 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto5.save(urine5CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine6"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 6"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine6">
                <v-menu
                  ref="menuto6"
                  v-model="menuto6"
                  :close-on-content-click="false"
                  :return-value.sync="urine6CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine6CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 6"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine6CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto6 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto6.save(urine6CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine7"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 7"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine7">
                <v-menu
                  ref="menuto7"
                  v-model="menuto7"
                  :close-on-content-click="false"
                  :return-value.sync="urine7CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine7CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 7"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine7CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto7 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto7.save(urine7CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine8"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 8"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine8">
                <v-menu
                  ref="menuto8"
                  v-model="menuto8"
                  :close-on-content-click="false"
                  :return-value.sync="urine8CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine8CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 8"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine8CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto8 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto8.save(urine8CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine9"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 9"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine9">
                <v-menu
                  ref="menuto9"
                  v-model="menuto9"
                  :close-on-content-click="false"
                  :return-value.sync="urine9CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine9CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 9"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine9CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto9 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto9.save(urine9CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine10"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 10"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine10">
                <v-menu
                  ref="menuto10"
                  v-model="menuto10"
                  :close-on-content-click="false"
                  :return-value.sync="urine10CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine10CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 10"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine10CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto10 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto10.save(urine10CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine11"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 11"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine11">
                <v-menu
                  ref="menuto11"
                  v-model="menuto11"
                  :close-on-content-click="false"
                  :return-value.sync="urine11CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine11CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 11"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine11CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto11 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto11.save(urine11CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine12"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 12"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine12">
                <v-menu
                  ref="menuto12"
                  v-model="menuto12"
                  :close-on-content-click="false"
                  :return-value.sync="urine12CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine12CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 12"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine12CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto12 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto12.save(urine12CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine13"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 13"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine13">
                <v-menu
                  ref="menuto13"
                  v-model="menuto13"
                  :close-on-content-click="false"
                  :return-value.sync="urine13CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine13CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 13"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine13CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto13 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto13.save(urine13CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine14"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 14"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine14">
                <v-menu
                  ref="menuto14"
                  v-model="menuto14"
                  :close-on-content-click="false"
                  :return-value.sync="urine14CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine14CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 14"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine14CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto14 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto14.save(urine14CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine15"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 15"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine15">
                <v-menu
                  ref="menuto15"
                  v-model="menuto15"
                  :close-on-content-click="false"
                  :return-value.sync="urine15CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine15CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 15"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine15CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto15 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto15.save(urine15CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

          <v-col cols="12" md="12" sm="12" xs="12"
            ><v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine16"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 16"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine16">
                <v-menu
                  ref="menuto16"
                  v-model="menuto16"
                  :close-on-content-click="false"
                  :return-value.sync="urine16CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine16CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 16"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine16CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto16 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto16.save(urine16CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></v-col
          >

            </v-row></v-col
          >

          </v-card>

        <v-row>
          <v-card
            outline
            width="100%"
            class="ma-3 pa-4"
            v-if="urine == 'พบสารเสพติด' || acceptFlag == true"
          >
            <v-col cols="12"
              ><v-row>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <h3 style="font-weight: 800; color: #833133">
                    การดูแลหลังการบำบัด
                  </h3>
                </v-col>
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-select
                    v-model="supportive"
                    :items="supportiveItems"
                    label="การดูแล ส่งเสริม สนับสนุนผู้เสพหลังรับการบำบัด"
                    disabled
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-select
                    v-model="afterDrug"
                    :items="afterDrugitems"
                    label="การดำเนินชีวิตหลังการบำบัด"
                    disabled
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-row>
        <v-row>
          <v-col><v-btn @click="cancel()">ยกเลิก</v-btn></v-col>
          <v-col
            ><v-btn color="primary" @click="submit(identityId)"
              >บันทึก</v-btn
            ></v-col
          >
        </v-row>
      </v-form>
      <!-- </v-card> -->
    </v-row>
  </v-container>
</template>

<script>
import { Decode, Encode } from "@/services";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      checkValidate: true,
      lazy: false,
      loading: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [(v) => v.length <= 13 || "Max 13 characters"],
        maxlength10: [(v) => v.length <= 10 || "Max 10 characters"],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      id: "",
      identityId: "",
      name: "",
      nickname: "",
      age: null,
      gender: "",
      address: "",
      nationality: "",
      otherNationality: "",
      urine: "",
      urineItems: [
        { text: "ไม่พบสารเสพติด", value: "ไม่พบสารเสพติด" },
        { text: "พบสารเสพติด", value: "พบสารเสพติด" },
      ],
      urine2: "",
      urine3: "",
      urine4: "",
      urine5: "",
      urine6: "",
      urine7: "",
      urine8: "",
      urine9: "",
      urine10: "",
      urine11: "",
      urine12: "",
      urine13: "",
      urine14: "",
      urine15: "",
      urine16: "",
      urine2CheckDate: new Date().toISOString().substr(0, 10),
      urine3CheckDate: new Date().toISOString().substr(0, 10),
      urine4CheckDate: new Date().toISOString().substr(0, 10),
      urine5CheckDate: new Date().toISOString().substr(0, 10),
      urine6CheckDate: new Date().toISOString().substr(0, 10),
      urine7CheckDate: new Date().toISOString().substr(0, 10),
      urine8CheckDate: new Date().toISOString().substr(0, 10),
      urine9CheckDate: new Date().toISOString().substr(0, 10),
      urine10CheckDate: new Date().toISOString().substr(0, 10),
      urine11CheckDate: new Date().toISOString().substr(0, 10),
      urine12CheckDate: new Date().toISOString().substr(0, 10),
      urine13CheckDate: new Date().toISOString().substr(0, 10),
      urine14CheckDate: new Date().toISOString().substr(0, 10),
      urine15CheckDate: new Date().toISOString().substr(0, 10),
      urine16CheckDate: new Date().toISOString().substr(0, 10),
      acceptFlag: false,
      menufrom: false,
      menuto: false,
      phone: "",
      drugTimes: "",
      drugType: "",
      drugTypeOther: "",
      otherPerson: "",
      otherPersonDetail: "",
      pic1: "",
      picture_1: "",
      imageName1: "ภาพถ่าย(ถ้ามี)",
      imageBase1: "",
      pic2: "",
      picture_2: "",
      imageName2: "ภาพถ่าย(ถ้ามี)",
      imageBase2: "",
      treatment: "",
      treatmentItems: [
        { text: "เข้ารับการบำบัด", value: "เข้ารับการบำบัด" },
        { text: "ไม่เข้ารับการบำบัด", value: "ไม่เข้ารับการบำบัด" },
      ],
      afterDrug: "",
      afterDrugitems: [
        { text: "มีอาชีพ", value: 1 },
        { text: "กลับมาเสพ", value: 2 },
        { text: "กลับมาเป็นผู้ขาย", value: 3 },
        { text: "ยังว่างงาน", value: 4 },
      ],
      updatedAt: "",
      supportive: "",
      supportiveItems: [
        { text: "ฝึกอาชีพ", value: "ฝึกอาชีพ" },
        { text: "จ้างงาน ", value: "จ้างงาน" },
        { text: "การศึกษา ", value: "การศึกษา" },
        { text: "ดูแลตนเอง ", value: "ดูแลตนเอง" },
      ],
      caseType: "",
      caseTypeItems: [
        { text: "คดีจำหน่าย", value: "คดีจำหน่าย" },
        { text: "คดีครองครองเพื่อจำหน่าย", value: "คดีครองครองเพื่อจำหน่าย" },
        { text: "คดีครอบครอง", value: "คดีครอบครอง" },
        { text: "คดีเสพ", value: "คดีเสพ" },
      ],
      createdUser: "",
      sum: null,
      idCardCheck: false,
    };
  },
  async created() {
    var data = JSON.parse(Decode.decode(localStorage.getItem("data")));
    console.log("dadadada", data);
    this.id = data.id;
    this.identityId = data.identityId;
    this.name = data.name;
    this.nickname = data.nickname;
    if (data.age) {
      this.age = data.age.toString();
    } //
    this.gender = data.gender; //
    this.address = data.address; //
    this.nationality = data.nationality;
    this.otherNationality = data.otherNationality;
    // this.houseNo = data.houseNo;
    // this.moo = data.moo;a
    this.urine = data.urine;
    this.urine2 = data.urine2;
    this.urine3 = data.urine3;
    this.urine4 = data.urine4;
    this.urine5 = data.urine5;
    this.urine6 = data.urine6;
    this.urine7 = data.urine7;
    this.urine8 = data.urine8;
    this.urine9 = data.urine9;
    this.urine10 = data.urine10;
    this.urine11 = data.urine11;
    this.urine12 = data.urine12;
    this.urine13 = data.urine13;
    this.urine14 = data.urine14;
    this.urine15 = data.urine15;
    this.urine16 = data.urine16;
    this.urine2CheckDate = data.urine2CheckDate; //
    this.urine3CheckDate = data.urine3CheckDate;
    this.urine4CheckDate = data.urine4CheckDate;
    this.urine5CheckDate = data.urine5CheckDate;
    this.urine6CheckDate = data.urine6CheckDate;
    this.urine7CheckDate = data.urine7CheckDate;
    this.urine8CheckDate = data.urine8CheckDate;
    this.urine9CheckDate = data.urine9CheckDate;
    this.urine10CheckDate = data.urine10CheckDate;
    this.urine11CheckDate = data.urine11CheckDate;
    this.urine12CheckDate = data.urine12CheckDate;
    this.urine13CheckDate = data.urine13CheckDate;
    this.urine14CheckDate = data.urine14CheckDate;
    this.urine15CheckDate = data.urine15CheckDate;
    this.urine16CheckDate = data.urine16CheckDate; //
    this.acceptFlag = data.acceptFlag; //
    this.phone = data.phone; //
    this.drugTimes = data.drugTimes; //
    this.otherPerson = data.otherPerson;
    this.otherPersonDetail = data.otherPersonDetail;
    this.drugType = data.drugType; //
    this.pic1 = data.pic1; //
    this.pic2 = data.pic2; //
    this.area = data.area; //
    this.province = data.province; //
    this.station = data.station; //
    this.userId = data.id; //////
    this.treatment = data.treatment;
    this.supportive = data.supportive;
    this.caseType = data.caseType;
    this.afterDrug = data.afterDrug;
    this.updatedAt = moment(String(data.updatedAt)).format("DD/MM/YYYY hh:mm");

    const response = await this.axios.get(
      `${process.env.VUE_APP_API}/user/getOne/` + data.userId
    );

    this.createdUser = response.data.data.name;
  },
  methods: {
    async checkIdcard(id) {
      this.sum = null;
      if (this.nationality == "ไทย") {
        for (let i = 0; i < 12; i++) {
          // const element = array[index];
          this.sum += parseFloat(id.charAt(i)) * (13 - i);
          console.log(this.sum, "sum");
        }
        // console.log(parseFloat(id.charAt(12)), "หลัง");
        // console.log((11 - (this.sum % 11)) % 10, "หน้า");
        if ((11 - (this.sum % 11)) % 10 == parseFloat(id.charAt(12))) {
          console.log(this.sum, "ผ่าน");
          this.idCardCheck = true;
        } else if ((11 - (this.sum % 11)) % 10 != parseFloat(id.charAt(12))) {
          console.log(this.sum, "ไม่ผ่าน");
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "หมายเลขบัตรประชาชนไม่ถูกต้อง",
          });
        }
      } else if (this.nationality != "ไทย") {
        this.idCardCheck = true;
      }
    },
    cancel() {
      this.$router.push("ManageForm3");
    },
    async submit(id) {
      await this.checkIdcard(id);
      if (this.$refs.createForm.validate(true) && this.idCardCheck) {
        this.loading = true;
        const data = {
          id: this.id,
          identityId: this.identityId,
          name: this.name,
          nickname: this.nickname,
          age: this.age, //
          gender: this.gender,
          address: this.address,
          nationality: this.nationality,
          otherNationality: this.otherNationality,
          urine: this.urine,
          urine2: this.urine2,
          urine3: this.urine3,
          urine2CheckDate: this.urine2CheckDate, //
          urine3CheckDate: this.urine3CheckDate, //
          acceptFlag: this.acceptFlag,
          phone: this.phone,
          drugTimes: this.drugTimes,
          drugType: this.drugType,
          otherPerson: this.otherPerson,
          otherPersonDetail: this.otherPersonDetail,
          caseType: this.caseType,
          pic1: this.imageBase1 || "",
          pic2: this.imageBase2 || "",
          treatment: this.treatment,
          afterDrug: this.afterDrug,
          supportive: this.supportive,
          urine4: this.urine4,
          urine5: this.urine5,
          urine6: this.urine6,
          urine7: this.urine7,
          urine8: this.urine8,
          urine9: this.urine9,
          urine10: this.urine10,
          urine11: this.urine11,
          urine12: this.urine12,
          urine13: this.urine13,
          urine14: this.urine14,
          urine15: this.urine15,
          urine16: this.urine16,
          urine4CheckDate: this.urine4CheckDate,
          urine5CheckDate: this.urine5CheckDate,
          urine6CheckDate: this.urine6CheckDate,
          urine7CheckDate: this.urine7CheckDate,
          urine8CheckDate: this.urine8CheckDate,
          urine9CheckDate: this.urine9CheckDate,
          urine10CheckDate: this.urine10CheckDate,
          urine11CheckDate: this.urine11CheckDate,
          urine12CheckDate: this.urine12CheckDate,
          urine13CheckDate: this.urine13CheckDate,
          urine14CheckDate: this.urine14CheckDate,
          urine15CheckDate: this.urine15CheckDate,
          urine16CheckDate: this.urine16CheckDate, 
        };
        console.log("data", data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/drugrelatedlist/update`,
          data
        );
        if (response.data.response_status === "SUCCESS") {
          console.log("response", response);
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "แก้ไขข้อมูลสำเร็จ",
          });
          this.$router.push("ManageForm3");
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "แก้ไขข้อมูลไม่สำเร็จ",
          });
        }
        this.loading = false;
      }
      else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "กรุณากรอกข้อมูลให้ครบ",
          });
        }
    },
    showPicture1(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase1 = reader.result;
          this.pic1 = URL.createObjectURL(element);
          console.log(this.imageBase1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase2 = reader.result;
          this.pic2 = URL.createObjectURL(element);
          console.log(this.imageBase2);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>